/* eslint-disable import/no-unresolved */
import * as AuthTypes from '@firebase/auth-types';
import { useFirebase } from '@taraai/read-write';
import Firebase from 'firebase/compat/app';
import { identify as identifyFullstory } from 'fullstory';
import { intercomInit, intercomUpdate } from 'intercom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getOrg, getOrgTeams, selectActiveOrgIds, selectActiveWorkspace, selectProfile } from 'reduxStore';
import { PROD } from 'tools';
import { segment } from 'tools/libraries/analytics';

export interface AuthProps {
  children: React.ReactNode;
}

/**
 * Auth component wraps the whole application and provides onAuthStateChanged callback for Firebase Auth.
 *
 * All the 3rd party services that depend on current user auth data should be initialized there
 * (such as FullStory, Intercom, Firebase Analytics, etc).
 *
 * This component depends on `read-write`'s `useFirebase` hook, so it should be
 * used inside `ReactReduxFirebaseProvider` only.
 *
 * Do not duplicate this callback anywhere else in the app, even though SDK allows that.
 * The only exception is FirebaseAuthUI but it also should be replaced with built-in solution soon.
 */
export default function Auth({ children }: AuthProps): JSX.Element {
  const firebase = useFirebase();

  const orgId = useSelector(selectActiveWorkspace);
  const organization = useSelector(getOrg(orgId).selector);
  const workspacesCount = useSelector(compose((data) => data?.length, selectActiveOrgIds));
  const teamsCount = useSelector(compose((teams) => (teams ?? []).length, getOrgTeams(orgId).selector));

  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState<AuthTypes.User>();

  const totalSprintsCreated = organization?.totalSprintsCount;
  const isFirstSprintComplete = organization?.completedSprintsCount && organization?.completedSprintsCount > 0;
  const totalTasksCreated = organization?.totalTasksCount;

  const profile = useSelector(selectProfile);

  // Initialize auth state listener and save firebase user to component state
  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser: AuthTypes.User | null): void => {
      if (firebaseUser) {
        setUser(firebaseUser);
        segment.identify(firebaseUser.uid, {
          ...profile,
          ...firebaseUser.toJSON(),
        });
      } else {
        segment.logout();
      }
    });
  });

  // Setup services as soon as user signs in
  useEffect(() => {
    if (!isInitialized && user?.metadata?.creationTime) {
      // Prepare shared data
      const email = user.email || 'unknown_email';
      const displayName = user.displayName || 'unknown';

      // Setup Intercom
      // Intercom expects time to be presented as seconds
      const createdAt = Math.floor(new Date(user.metadata.creationTime).getTime() / 1000);
      orgId && intercomInit(email, displayName, createdAt, orgId, teamsCount);

      // Setup Fullstory
      identifyFullstory(user.uid, {
        email,
        displayName,
        orgName: orgId,
      });

      // Setup Firebase Analytics (@debug https://bit.ly/39BeFLq)
      if (PROD) {
        const firebaseAnalytics = Firebase.analytics();
        firebaseAnalytics.setUserId(user.uid);
        firebaseAnalytics.setUserProperties({
          userID: user.uid,
          orgName: orgId,
        });
      }

      setIsInitialized(true);
    }
  }, [isInitialized, orgId, user, teamsCount]);

  // Listen to user stats and send updates to Intercom when they change.
  // TODO: It is a temporary solution, all statistics will be moved to backend soon.
  useEffect(() => {
    intercomUpdate(isFirstSprintComplete, totalSprintsCreated, totalTasksCreated, workspacesCount);
  }, [isFirstSprintComplete, totalSprintsCreated, totalTasksCreated, workspacesCount]);
  return <>{children}</>;
}
