/* eslint-disable */

function intercom() {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;

    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://widget.intercom.io/widget/sw0kkkm7';
    const x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
}

// We don't want to accidentally override old settings when adding new ones.
// That's why settings update is moved to the helper function that merges
// old and new values.
function updateIntercomSettings(newSettings) {
  window.intercomSettings = {
    ...(window.intercomSettings || {}),
    ...newSettings,
  };
}

export function intercomInit(userEmail, userName, userCreatedAt, orgId, teamsCount) {
  updateIntercomSettings({
    app_id: 'sw0kkkm7',
    email: userEmail,
    name: userName,
    created_at: userCreatedAt,
    company: {
      'company_id': orgId,
      'name': orgId,
      'Number of Teams': teamsCount,
    },
  });

  intercom();
}

export function intercomUpdateCompany(orgId, teamsCount) {
  updateIntercomSettings({
    company: {
      'company_id': orgId,
      'name': orgId,
      'Number of Teams': teamsCount,
    },
  });

  intercom();
}

export function intercomUpdate(isFirstSprintComplete, numberOfSprints, totalTasksCreated, workspacesCount) {
  updateIntercomSettings({
    'Is First Sprint Complete': isFirstSprintComplete,
    'Number of Sprints Created': numberOfSprints,
    'Number of Tasks Created': totalTasksCreated,
    'Number of Workspaces': workspacesCount,
  });

  intercom();
}

export function intercomUpdateInvitedAtTime(orgId, timestamp) {
  updateIntercomSettings({
    company: {
      'company_id': orgId,
      'Invite Accepted Date': timestamp,
    },
  });

  intercom();
}
