import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Fluid, HStack, Popup, Spacer, Switch, Text, tokens, Tooltip, VStack } from '@taraai/design-system';
import { Data, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { AddActionBox } from 'components/app/AddActionBox/AddActionBox';
import { DraggableElement, DraggableType, DroppableType } from 'components/app/DragAndDrop';
import { TaskCard } from 'components/app/TaskCard';
import { TaskCreation } from 'components/app/TaskCreation';
import Icon from 'components/core/controllers/views/Icon';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import React, { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createTask, selectActiveWorkspace, useAppDispatch } from 'reduxStore';
import { useAppIsSelected } from 'reduxStore/appSelection';
import {
  SettingType,
  useWorkDrawerScrollBlock,
  useWorkDrawerSettingsState,
  useWorkDrawerSettingsToggleState,
} from 'reduxStore/workDrawer';
import { strings } from 'resources';
import { IconName } from 'resources/assets/icons';
import { useListenToEscapeKey } from 'tools';
import { segment } from 'tools/libraries/analytics';
import { useToast } from 'tools/utils/hooks/useToast';

export function WorkDrawerHeader(): JSX.Element {
  return (
    <Box background='$white' data-intercom-target='Work Drawer Header' spaceHorz='$8px' spaceVert='$12px'>
      <HStack alignY='center'>
        <Text size='$12px' weight='medium'>
          {strings.workDrawer.title}
        </Text>
        <Fluid />
        <Text color='$grey6' size='$10px' weight='bold'>
          {strings.workDrawer.filters}
        </Text>
        <Spacer space='$12px' />
        <FilterButton iconName='tasksFilterAssigned' setting='tasks:show-assigned-to-sprint' />
        <Spacer space='$8px' />
        <FilterButton iconName='tasksFilterDone' setting='tasks:show-done' />
        <Spacer space='$8px' />
        <WorkDrawerSettings />
      </HStack>
    </Box>
  );
}

type FilterButtonSettingType = SettingType & ('tasks:show-assigned-to-sprint' | 'tasks:show-done');

function FilterButton({ iconName, setting }: { iconName: IconName; setting: FilterButtonSettingType }): JSX.Element {
  const settingState = useWorkDrawerSettingsState(setting);
  const iconColor = settingState ? tokens.colors.$indigo : tokens.colors.$grey6;
  const tooltipTitle = strings.workDrawer.settingsTooltips[setting][settingState ? 'on' : 'off'];
  return (
    <Tooltip title={tooltipTitle}>
      <Box.Button onClick={useWorkDrawerSettingsToggleState(setting)}>
        <Icon autoSize color={iconColor} name={iconName} noPadding />
      </Box.Button>
    </Tooltip>
  );
}

export function WorkDrawerSettings(): JSX.Element {
  const [show, setShow] = useState(false);
  return (
    <Popup
      content={
        <Popup.Content>
          <WorkDrawerSettingsPopup />
        </Popup.Content>
      }
      onHide={() => setShow(false)}
      placement='bottom-end'
      show={show}
    >
      <Box.Button onClick={() => setShow((prev) => !prev)}>
        <Icon autoSize color={show ? tokens.colors.$dark : tokens.colors.$grey5} name='kebab2' noPadding />
      </Box.Button>
    </Popup>
  );
}

function WorkDrawerSettingsPopup(): JSX.Element {
  return (
    <VStack space='$16px'>
      <WorkDrawerSettingsPopupHeader>{strings.workDrawer.settingsHeaders.requirements}</WorkDrawerSettingsPopupHeader>
      <WorkDrawerSettingsPopupItem settingType='requirementsSection:show-archived' />
    </VStack>
  );
}

function WorkDrawerSettingsPopupHeader({ children }: { children: string }): JSX.Element {
  return (
    <Text color='$grey7' size='$12px' weight='medium'>
      {children}
    </Text>
  );
}

function WorkDrawerSettingsPopupItem({ settingType }: { settingType: SettingType }): JSX.Element {
  return (
    <HStack alignY='center' space='$12px'>
      <Fluid>
        <Text color='$grey7' size='$12px'>
          {strings.workDrawer.settingsDescriptions.showArchived}
        </Text>
      </Fluid>
      <Switch
        checked={useWorkDrawerSettingsState(settingType)}
        onChange={useWorkDrawerSettingsToggleState(settingType)}
      />
    </HStack>
  );
}

export type WorkDrawerTaskCardProps = Pick<UI.UITask, 'id'>;

export function TaskCards({
  onTaskSelect,
  taskIds,
  sourceType = DroppableType.workdrawerTasks,
  isDraggable = true,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  taskIds: Data.Id.TaskId[];
  sourceType?: DroppableType;
  isDraggable?: boolean;
}): JSX.Element {
  return (
    <Box spaceLeft='$12px'>
      <VStack space='$1px'>
        {taskIds.map((taskId, index) => (
          <TaskCardsItem
            key={taskId}
            index={index}
            isDraggable={isDraggable}
            onTaskSelect={onTaskSelect}
            sourceType={sourceType}
            taskId={taskId}
          />
        ))}
      </VStack>
    </Box>
  );
}

export const TaskCardsWraper = React.memo(function TaskCardsWrapper({
  onTaskSelect,
  taskIds,
  requirementId,
  initialShowCreation = false,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  taskIds: Data.Id.TaskId[];
  requirementId?: Data.Id.RequirementId;
  initialShowCreation?: boolean;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const [showCreation, setShowCreation] = useState(false);
  const createInlineTaskRef = useRef<RichEditorHandle>(null);
  const submitting = useRef(false);
  const orgId = useSelector(selectActiveWorkspace);
  const { addToast } = useToast();
  submitting.current = false;

  useEffect(() => {
    if (!initialShowCreation && showCreation === true) setShowCreation(false);
    if (showCreation) createInlineTaskRef.current?.focus();
  }, [showCreation, setShowCreation, initialShowCreation]);

  const handleAddClick = useCallback((): void => {
    setShowCreation(true);
  }, []);

  const handleTaskCreation = useCallback(
    async (title: string) => {
      if (submitting.current) return;
      submitting.current = true;

      const partialTask = extractMentions(extractEffortLevel({ title }));
      const labels = unique(parseLabelsFromPlainText(partialTask.title));
      const taskToCreate = requirementId
        ? { ...partialTask, labels, _relationships: { requirement: requirementId } }
        : { ...partialTask, labels };

      await dispatch(createTask({ ...taskToCreate, taskIds }))
        .then(unwrapResult)
        .then((newTaskId) => {
          segment.track('TaskCreated', { orgID: orgId, taskID: newTaskId, location: 'WorkDrawer' });
        })
        .catch(() => addToast({ type: 'error', message: strings.task.failedToCreateTask }));

      submitting.current = false;

      createInlineTaskRef.current?.clear();
      setShowCreation(false);
      submitting.current = false;
    },
    [addToast, dispatch, orgId, requirementId, taskIds],
  );

  const handleEmptyTaskTitle = useMemo(
    () => (): void => {
      setShowCreation(false);
    },
    [],
  );

  return (
    <TaskCardsWrapperContent
      createInlineTaskRef={createInlineTaskRef}
      handleAddClick={handleAddClick}
      handleEmptyTaskTitle={handleEmptyTaskTitle}
      handleTaskCreation={handleTaskCreation}
      onExitCreation={setShowCreation}
      onTaskSelect={onTaskSelect}
      showCreation={showCreation}
      taskIds={taskIds}
    />
  );
});

const TaskCardsWrapperContent = React.memo(function TaskCardsWrapperContent({
  showCreation,
  createInlineTaskRef,
  taskIds,
  handleAddClick,
  handleTaskCreation,
  handleEmptyTaskTitle,
  onTaskSelect,
  onExitCreation,
}: {
  showCreation: boolean;
  createInlineTaskRef: RefObject<RichEditorHandle>;
  taskIds: Data.Id.TaskId[];
  handleAddClick: () => void;
  handleTaskCreation: (title: string) => Promise<void>;
  handleEmptyTaskTitle?: () => void;
  onTaskSelect: (taskId: string) => void;
  onExitCreation: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  useListenToEscapeKey('Escape', onExitCreation, false);

  return (
    <Box spaceLeft='$12px' spaceTop='$2px'>
      <VStack space='$1px'>
        {taskIds.map((taskId, index) => (
          <Box key={taskId}>
            <span>
              <TaskCardsItem
                index={index}
                onTaskSelect={onTaskSelect}
                sourceType={DroppableType.workdrawerTasks}
                taskId={taskId}
              />
            </span>
          </Box>
        ))}

        {showCreation && (
          <TaskCreationBox
            createInlineTaskRef={createInlineTaskRef}
            handleEmptyTaskTitle={handleEmptyTaskTitle}
            handleTaskCreation={(title: string) => handleTaskCreation(title)}
          />
        )}

        {!showCreation &&
          (taskIds.length > 0 ? <AddActionBox onClick={handleAddClick} text={strings.tasks.addTask} /> : null)}
      </VStack>
    </Box>
  );
});

export function TaskCardsItem({
  onTaskSelect,
  taskId,
  index,
  sourceType,
  isDraggable = true,
}: {
  onTaskSelect: (taskId: Data.Id.TaskId) => void;
  taskId: Data.Id.TaskId;
  index: number;
  sourceType: DroppableType;
  isDraggable?: boolean;
}): JSX.Element {
  const block = useWorkDrawerScrollBlock();
  const selected = useAppIsSelected('requirement', taskId);
  const ref = useScrollIntoViewRef<HTMLDivElement>(selected, block);

  const orgId = useSelector(selectActiveWorkspace);

  const onClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      return onTaskSelect(taskId);
    },
    [onTaskSelect, taskId],
  );

  const item = (
    <Box.Button key={taskId} ref={ref} onClick={onClick}>
      <Box background='$white' spaceBottom='$8px'>
        <TaskCard key={taskId} id={taskId} inWorkDrawer path={`orgs/${orgId}/tasks`} />
      </Box>
    </Box.Button>
  );

  return isDraggable ? (
    <DraggableElement
      key={`WD_${taskId}_${sourceType}`}
      index={index}
      sortable={false}
      sourceType={sourceType}
      type={DraggableType.workDrawerTask}
    >
      {item}
    </DraggableElement>
  ) : (
    <>{item}</>
  );
}

export const TopLevelCreationButton = forwardRef<HTMLDivElement, { onClick: () => void } & { text: string }>(
  function TopLevelCreationButton({ onClick, text }, ref): JSX.Element {
    return (
      <Box.Button
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        <Text color='$focus' size='$14px'>
          {text}
        </Text>
      </Box.Button>
    );
  },
);

export function useScrollIntoViewRef<RefElement extends Element>(
  active: boolean,
  block: 'nearest' | 'center',
): RefObject<RefElement> {
  const ref = useRef<RefElement>(null);
  useEffect(() => {
    if (active) {
      ref.current?.scrollIntoView({ block, inline: 'nearest' });
    }
  }, [active, block]);
  return ref;
}

export const CreateTaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$12px',
  caretColor: 'colors.$focus',
});

type TaskCreationBoxProps = {
  handleTaskCreation: (title: string) => Promise<void> | void;
  handleEmptyTaskTitle?: () => void;
  createInlineTaskRef: React.RefObject<RichEditorHandle>;
};

export const TaskCreationBox = ({
  handleTaskCreation,
  handleEmptyTaskTitle,
  createInlineTaskRef,
}: TaskCreationBoxProps): JSX.Element => (
  <Box spaceTop='$1px'>
    <Box background='$white' border='$grey4' borderRadius='$2px' space='$8px' spaceRight='$4px'>
      <TaskCreation handleEmptyTaskTitle={handleEmptyTaskTitle} onEnter={(title: string) => handleTaskCreation(title)}>
        <CreateTaskEditor ref={createInlineTaskRef} placeholder={strings.workDrawer.createTask} saveOnBlur />
      </TaskCreation>
    </Box>
  </Box>
);

export const addIcon = (
  <svg fill='none' height='14' stroke={tokens.colors.$grey6} width='14' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 4V10M4 7H10' strokeLinecap='round' />
    <circle cx='7' cy='7' r='6.5' />
  </svg>
);
