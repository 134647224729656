/* eslint-disable sonarjs/cognitive-complexity */
import { Avatar, Box, fade, Fluid, Hidden, HStack, styled, Text, Tooltip, VStack } from '@taraai/design-system';
import { useCache } from '@taraai/read-write';
import { Data, UI } from '@taraai/types';
import { parseLabelsFromPlainText, unique } from '@taraai/utility';
import { UserSelector } from 'components/app/controllers/Selectors/UserSelector';
import { SmartText } from 'components/app/controllers/views/SmartText';
import { TaskCreation } from 'components/app/TaskCreation';
import { EffortInput } from 'components/app/TaskDetails/common/EffortInput';
import { SectionType } from 'components/core/controllers/Selector';
import { StatusSelector } from 'components/core/controllers/StatusSelector';
import { useTaskTitleConfig } from 'components/editor/config';
import { extractEffortLevel, extractMentions } from 'components/editor/plugins';
import { getStyledRichEditor, RichEditorHandle } from 'components/editor/RichEditor';
import { useGetRequirementFields } from 'firestore';
import React, { SyntheticEvent, useRef, useState } from 'react';
import deepEquals from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getUserFragment, selectActiveUsers, selectActiveWorkspace, updateTask, useAppDispatch } from 'reduxStore';
import { useAppIsSelected } from 'reduxStore/appSelection';
import { PencilIcon } from 'resources/assets/icons/components';
import { strings } from 'resources/i18n';
import { StopPropagation } from 'tools/helpers/StopPropagation';
import { mapTaskStatusToColor } from 'tools/utils/mapTaskStatusToColor';

type UserFragment = Pick<UI.UIUser, 'id' | 'name' | 'avatarURL'>;

type Props = {
  id: Data.Id.TaskId;
  path: Data.Path.TaskPath;
  overloaded?: boolean;
  filteredByStatus?: boolean;
  inWorkDrawer?: boolean;
};

type Required<T, K extends keyof T> = T & { [P in K]-?: T[P] };
type WithRequired<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>> & Required<T, K>;
type UITask = WithRequired<UI.UITask, 'path'>;
type UIUser = WithRequired<UI.UIUser, 'path'>;

export const TaskCard = React.memo(function TaskCard({
  id,
  path,
  filteredByStatus,
  overloaded,
  inWorkDrawer,
}: Props): JSX.Element {
  const taskTitleRef = useRef<RichEditorHandle>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const selected = useAppIsSelected('task', id);

  const dispatch = useAppDispatch();
  const handleTextClick = (event: SyntheticEvent): void => {
    event.stopPropagation();
    if (taskTitleRef.current) taskTitleRef.current.focus();
    setIsEditing(true);
    setShowOptions(true);
  };

  const onAnimationEnd = (): void => {
    if (!showOptions) setIsEditing(false);
  };

  const orgId = useSelector(selectActiveWorkspace);

  const { assignee, effortLevel, sprint, status, title, _relationships } =
    useCache<UITask, 'assignee' | 'effortLevel' | 'sprint' | 'status' | 'title' | '_relationships'>({ path, id }, [
      'assignee',
      'effortLevel',
      'sprint',
      'status',
      'title',
      '_relationships',
    ]) ?? ({} as UITask);

  const { avatarURL, name } =
    useCache<UIUser, 'avatarURL' | 'name'>({ path: 'users', id: assignee ?? '' }, ['avatarURL', 'name']) ??
    ({} as UIUser);

  const requirementPartial = useGetRequirementFields(_relationships.requirement || null, ['title']);

  const taskRequirement = requirementPartial?.title;

  const showAssignedToSprint = inWorkDrawer && sprint !== null;

  const selection = assignee && name && avatarURL ? [{ id: assignee, name, avatarURL }] : [];

  const users: UserFragment[] = useSelector(compose(getUserFragment, selectActiveUsers(orgId)), deepEquals);

  const sections: SectionType<UserFragment>[] = [{ id: 'users', options: users }];

  const handleTaskTitleChange = (newTitle: string): void => {
    setIsEditing(false);
    updateTaskWithTitle(newTitle);
    setShowOptions(false);
  };

  const removeTaskAssignee = async (): Promise<void> => {
    await dispatch(updateTask({ id, assignee: null }));
  };

  const setTaskAssignee = async ({ id: assigneeId }: UserFragment): Promise<void> => {
    await dispatch(updateTask({ id, assignee: assigneeId }));
  };

  const updateTaskWithTitle = async (newTitle: string): Promise<void> => {
    const { collaborators, ...taskData } = extractMentions(extractEffortLevel({ title: newTitle }));
    const labels = unique(parseLabelsFromPlainText(taskData.title));
    await dispatch(
      updateTask({
        id,
        labels,
        collaborators: collaborators.length ? ['::arrayUnion', ...collaborators] : undefined,
        ...taskData,
      }),
    );
  };

  const Wrapper = styled(
    Box,
    {
      'backgroundColor': '$white',
      'border': '0.5px solid #EAEEF5',
      ':hover': {
        backgroundColor: '$grey1',
      },
    },
    {
      selected: { true: { boxShadow: 'inset 0 0 0 1px colors.$lavender' } },
      isFiltered: { true: { background: fade(mapTaskStatusToColor[status], '$5%') } },
      isOverloaded: { true: { background: fade('$failure', '$5%') } },
      showAssigned: { true: { background: '$grey1' } },
    },
  );

  return (
    <Tooltip
      disabled={!showAssignedToSprint}
      placement='right'
      title={String(strings.formatString(strings.workDrawer.taskMovedToSprint))}
    >
      <Box background='$white' borderRadius='$2px'>
        <Wrapper
          borderRadius='$2px'
          isFiltered={filteredByStatus}
          isOverloaded={overloaded}
          selected={selected}
          showAssigned={showAssignedToSprint}
          space='$8px'
          spaceBottom='$12px'
          style={{ borderBottom: '1px solid $grey3' }}
        >
          <OpacityWrapper inSprint={showAssignedToSprint}>
            <VStack space='$4px'>
              <Fluid>
                <Hidden hidden={!isEditing} strategy='remove'>
                  <StopPropagation>
                    <TaskCreation
                      initialValue={title}
                      onEnter={handleTaskTitleChange}
                      optionsAnimation={{ onAnimationEnd, show: showOptions }}
                    >
                      <CreateTaskEditor ref={taskTitleRef} placeholder={strings.workDrawer.createTask} saveOnBlur />
                    </TaskCreation>
                  </StopPropagation>
                </Hidden>

                {/* Task name */}
                <Hidden hidden={isEditing}>
                  <StyledText color='$coreGrey' italic={showAssignedToSprint} size='$14px'>
                    <SmartText config={useTaskTitleConfig()} text={title} />
                    <EditTaskIconContainer onClick={handleTextClick}>{PencilIcon}</EditTaskIconContainer>
                  </StyledText>
                </Hidden>
              </Fluid>
            </VStack>

            {/* Req name */}
            <VStack>
              {taskRequirement && taskRequirement?.length > 30 ? (
                <Tooltip
                  placement='bottom-start'
                  title={taskRequirement && taskRequirement?.length > 30 ? taskRequirement : ''}
                >
                  <StyledText color='$coreGrey' size='$10px' style={{ marginBottom: '6px' }} weight='medium'>
                    {taskRequirement && <RequirementBadge>{taskRequirement.substring(0, 30)}...</RequirementBadge>}
                  </StyledText>
                </Tooltip>
              ) : (
                <StyledText color='$coreGrey' size='$10px' style={{ marginBottom: '6px' }} weight='medium'>
                  {taskRequirement && <RequirementBadge>{taskRequirement}</RequirementBadge>}
                </StyledText>
              )}
            </VStack>

            <VStack alignY='center' style={{ marginTop: '-6px' }}>
              {/* Controls/selectors */}
              <HStack>
                <Fluid>
                  <StyledTaskId color='$focus' size='$10px' weight='medium'>
                    {strings.formatString(strings.task.taskId, { taskId: id })}
                  </StyledTaskId>
                </Fluid>
                <HStack alignY='bottom' space='$4px'>
                  <Box>
                    <StatusSelector status={status ?? 0} taskId={id} />
                  </Box>
                  <EffortInput backgroundColor='$grey4' color='$dark' effortLevel={effortLevel} taskId={id} />
                  <UserSelector
                    closePopupOnSelection
                    headerTitle={strings.taskSidebar.modules.assignee.dropdown}
                    onDeselectOption={removeTaskAssignee}
                    onSelectOption={setTaskAssignee}
                    optionSize='small'
                    renderSelectButton={({ openPopup }) => (
                      <Box.Button
                        onClick={(event: SyntheticEvent) => {
                          event.stopPropagation();
                          openPopup();
                        }}
                      >
                        <Avatar title={name} url={avatarURL} />
                      </Box.Button>
                    )}
                    searchPlaceholder={strings.taskSidebar.modules.header.searchUsers}
                    sections={sections}
                    selection={selection}
                    selectorPosition='left'
                  />
                </HStack>
              </HStack>
            </VStack>
          </OpacityWrapper>
        </Wrapper>
      </Box>
    </Tooltip>
  );
});

const EditTaskIconContainer = styled(Box, {
  'height': '5px',
  'marginLeft': '5px',
  'marginTop': '2px',
  'opacity': '0',
  '&:hover': {
    color: '$darker',
    opacity: '1',
  },
});

const OpacityWrapper = styled(Box, {}, { inSprint: { true: { opacity: '0.6' } } });

const StyledText = styled(Text, { display: 'flex', wordBreak: 'break-word', textOverflow: 'ellipsis' });

const CreateTaskEditor = getStyledRichEditor({
  fontSize: '$12px',
  lineHeight: '$12px',
  caretColor: 'colors.$coreGrey',
  color: 'colors.$coreGray',
});

const StyledTaskId = styled(Text, {
  width: 'fit-content',
  textTransform: 'capitalize',
  color: '$coreGrey',
  paddingTop: '6px',
});

const RequirementBadge = styled(Box, {
  backgroundColor: '$grey2',
  borderRadius: '4px',
  padding: '8px 4px',
  width: 'fit-content',
});
